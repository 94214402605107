import React, { useEffect, useState } from "react";
import { IRelease } from "../../interfaces/IRelease";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { IChange } from "../../interfaces/IChange";
import { getTmpId } from "../core/helper";
import { IChangeType } from "../../interfaces/IChangeType";
import { ChangeAccordion } from "./ChangeAccordion";

export interface IProps {
    currentRelease: IRelease;
    setCurrentRelease: Function;
    changeTypeArray: IChangeType[];
}

export const getEmptyChange = (idRelease:number, changesArray:IChange[]) => {
    return {
        ChangeType: 1,
        descriptionHtml: "",
        dinoAcademyLink: null,
        id: getTmpId(changesArray),
        isHighlighted: false,
        Release: idRelease,
        title: ""
    } as IChange
}

export const ChangeSite:React.FC<IProps> = ({currentRelease,setCurrentRelease,changeTypeArray}) => {
    const [changeArray,setChangeArray] = useState(currentRelease.changes);

    const handleAddNew = () => {
        setChangeArray([
            ...changeArray,
            getEmptyChange(currentRelease.id,changeArray)
        ])
    }

    useEffect(() => {
        setCurrentRelease({
            ...currentRelease,
            changes: changeArray
        })
    },[changeArray])

    return(
        <>
            <Card variant="outlined" sx={{mt: 2}}>
                <CardContent>
                    <Typography>
                        Eintragungen
                        <Button variant="outlined" sx={{float: "right"}} onClick={handleAddNew}>Neuer Eintrag</Button>
                    </Typography>
                    <Box sx={{mt: 5}}/>

                    {changeArray.map(change =>
                        <ChangeAccordion
                            key={`edit-change-${change.id}`}
                            changeArray={changeArray}
                            changeTypeArray={changeTypeArray}
                            currentChange={change}
                            setChangeArray={setChangeArray}
                        />
                    )}
                </CardContent>
            </Card>
        </>
    )
}