import React from "react";
import { ISubscription } from "../../../interfaces/ISubscription";
import { Button, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { ExtensionSelect } from "./ExtensionSelect";
import { IContract } from "../../../interfaces/IContract";
import { IExtension } from "../../../interfaces/IExtension";


interface IProps {
    editSubscription: ISubscription | IContract;
    extensionArray: IExtension[];
    assignedExtension: number[];
    setAssignedExtension: Function;
    disableBtn?: boolean;
}


export const ExtensionTable: React.FC<IProps> = ({ editSubscription, extensionArray, assignedExtension, setAssignedExtension, disableBtn }) => {

    const handleRemoveMe = (idApp:number) => {
        setAssignedExtension([
            ...assignedExtension.filter(x => x !== idApp)
        ])
    }

    const tableBody = () => {
        if (editSubscription.isFreeAll == true) {
            return (
                <>
                    {extensionArray.map(x =>
                        <TableRow>
                            <TableCell>{x.ExtensionType === 1 ? "App" : "Modul"}</TableCell>
                            <TableCell>{Number(x.selling_price).toLocaleString("de-DE", { style: "currency", currency: "EUR" })}</TableCell>
                            <TableCell>{x.extension_name}</TableCell>
                            <TableCell>-</TableCell>
                        </TableRow>
                    )}
                    <TableRow><TableCell colSpan={3}><i>Automatisch alle neuen Module...</i></TableCell></TableRow>
                </>
            )
        } else {
            return (
                <>
                    {assignedExtension.map(idApp => {
                        let testApp = extensionArray.find(x => x.id === idApp);

                        if (testApp === undefined) {
                            return <TableRow><TableCell colSpan={3}><b>FEHLER</b></TableCell></TableRow>
                        }
                        else {
                            return (
                                <TableRow key={`row-app-${idApp}`}>
                                    <TableCell>{testApp.ExtensionType === 1 ? "App" : "Modul"}</TableCell>
                                    <TableCell>{Number(testApp.selling_price).toLocaleString("de-DE", { style: "currency", currency: "EUR" })}</TableCell>
                                    <TableCell>{testApp.extension_name}</TableCell>
                                    <TableCell>
                                        {(disableBtn === undefined || disableBtn === false) && 
                                            <IconButton size="small" onClick={() => handleRemoveMe(idApp)}><Delete /></IconButton>
                                        }
                                        
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    })}
                </>
            )
        }
    }


    return (
        <>
            <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
                Zugeordnete Module
                {(disableBtn === undefined || disableBtn === false) && 
                    <ExtensionSelect 
                        disabledBtn={editSubscription.isFreeAll} 
                        extensionArray={extensionArray} 
                        assignedExtension={assignedExtension} 
                        setAssignedExtension={setAssignedExtension}
                    />
                }
            </Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ width: 70 }}>Art</TableCell>
                        <TableCell sx={{ width: 70 }}>Preis</TableCell>
                        <TableCell>Modul</TableCell>
                        <TableCell sx={{ width: 50 }}>Aktionen</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableBody()}
                </TableBody>
            </Table>
        </>
    )
}