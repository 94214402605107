import React, { useState } from "react";
import { ISubscription } from "../../../interfaces/ISubscription";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { DataGrid, GridColDef, GridRowId, GridRowParams } from "@mui/x-data-grid";
import { IExtension } from "../../../interfaces/IExtension";


interface IProps {
    disabledBtn: boolean;
    extensionArray: IExtension[];
    assignedExtension: number[];
    setAssignedExtension: Function;
}

export const columns: GridColDef[] = [
    { 
        field: 'id', 
        headerName: 'Nr.', 
        width: 90 
    },
    { 
        field: 'ExtensionType', 
        headerName: 'Art', 
        valueGetter: (value) => Number(value) === 1 ? "App" : "Modul",
        width: 100 
    },
    { 
        field: 'system_key', 
        headerName: 'System-Schlüssel', 
        width: 200 
    },
    { 
        field: 'selling_price', 
        headerName: 'Preis (netto)', 
        valueGetter: (value) => Number(value).toLocaleString("de-DE", { style: "currency", currency: "EUR" }),
        width: 100 
    },
    { 
        field: 'extension_name', 
        headerName: 'Bezeichnung', 
        flex: 1
    },
]



export const ExtensionSelect:React.FC<IProps> = ({disabledBtn, extensionArray, assignedExtension,setAssignedExtension }) => {
    const [isOpenAdd,setIsOpenAdd] = useState(false);
    //
    const [addNewPartner,setAddNewPartner] = useState<GridRowId[]>([]);


    const handleAddNew = () => {
        setAddNewPartner([]);
        setIsOpenAdd(true)
    }

    const handleAddBtn = () => {

        setAssignedExtension([...assignedExtension, ...addNewPartner]);
        setIsOpenAdd(false);

    }


    return(
        <>
            <Dialog open={isOpenAdd} onClose={() => setIsOpenAdd(false)} maxWidth="md" fullWidth>
                <DialogTitle>Erweiterung hinzufügen</DialogTitle>
                <DialogContent>
                    <DataGrid
                        sx={{mt: 2}}
                        autoHeight
                        checkboxSelection 
                        rows={extensionArray}
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            setAddNewPartner([
                                ...newRowSelectionModel
                            ])                       
                        }}
                        rowSelectionModel={addNewPartner}
                        columns={columns}
                        //getRowId={(row) => row.idContact}
                        //isRowSelectable={(params: GridRowParams) => props.contactArray.map(x => x.idContact).indexOf(Number(params.row.idContact)) === -1 }
                        isRowSelectable={(params: GridRowParams) => assignedExtension.indexOf(Number(params.id)) === -1 }
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 50,
                                },
                            },
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setIsOpenAdd(false)}>Abbruch</Button>
                    <Button variant="contained" onClick={handleAddBtn}>Hinzufügen</Button>
                </DialogActions>
            </Dialog>
            <Button disabled={disabledBtn} variant="outlined" sx={{float: "right"}} onClick={handleAddNew}>Modul zuordnen</Button>
        </>
    )

}