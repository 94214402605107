import React, { useState } from "react";
import { IUser } from "../../../interfaces/IUser";
import { GridColDef } from "@mui/x-data-grid";
import { IObjectTableCol, ObjectTable } from "../../core/ObjectTable";
import { UserEdit } from "../../User/UserEdit";
import { ICustomer } from "../../../interfaces/ICustomer";
import { PersonEdit } from "../PersonEdit";
import { IGender } from "../../../interfaces/IGender";
import { IAcademicTitle } from "../../../interfaces/IAcademicTitle";
import { getIsAdmin } from "../../core/helper";
import { IContract } from "../../../interfaces/IContract";
import { IScheduling } from "../../../interfaces/IScheduling";
import { ISubscription } from "../../../interfaces/ISubscription";
import { ContractEdit } from "../../Contract/ContractEdit";
import { IExtension } from "../../../interfaces/IExtension";

interface IProps {
    idCompany: number;
    contractArray: IContract[];
    setContractArray: Function;

    contractIdArray: number[];
    setContractIdArray: Function;
    
    extensionArray: IExtension[];

    schedulingArray: IScheduling[];
    subscriptionArray: ISubscription[];
}

export const getEmptyContract = (idCompany:number) => {
    return {
        id: -1,
        contract_title: "",
        Scheduling: 1,
        Subscription: null,
        closed_at: null,
        Company: idCompany,
        license_key: "",
        contract_number: "",
        signed_at: "",
        selling_price: 0,
        active_at: "",
        isFreeAll: false,
        Assigned_Extension: []
    } as IContract
}




export const ContractTable:React.FC<IProps> = ({
    idCompany, contractArray, setContractArray,  
    contractIdArray, setContractIdArray, 
    extensionArray, schedulingArray, subscriptionArray
}) => {
    const isAdmin = getIsAdmin();

    const getWrapperEmptyContract = () => {
        return getEmptyContract(idCompany)
    }
    
    
    const [editContract,setEditContract] = useState(getWrapperEmptyContract());


    const columns: GridColDef[] = []

    const tableCol:IObjectTableCol[] = [
        {
            title: "Nr.",
            attr: "id",
            width: 50
        },
        {
            title: "Vertragsnummer",
            attr: "contract_number",
            width: 200
        },
        {
            title: "Von",
            attr: "active_at",
            width: 100,
            castDate: true
        },
        {
            title: "Bis",
            attr: "closed_at",
            width: 100,
            castDate: true
        },
        {
            title: "Vertrag",
            attr: "contract_title"
        }

    ]


    return(
        <>
            <ObjectTable
                columns={columns}
                objectTableCol={tableCol}
                title="Abbos"
                objectArray={contractArray}
                selectedId={contractIdArray}
                setSelectedId={setContractIdArray}
                //
                setObjectArray={setContractArray}
                editObject={editContract}
                setEditObject={setEditContract}
                getNewObject={getWrapperEmptyContract}
                uploadUrl="/company/contract/"
                disableBtns={!isAdmin}
                disableBtnAssign
            >
                <ContractEdit
                    editContract={editContract}
                    setEditContract={setEditContract}
                    extensionArray={extensionArray}
                    schedulingArray={schedulingArray}
                    subscriptionArray={subscriptionArray}
                />
            </ObjectTable>
        </>
    )
}