import React from "react";
import { Grid, MenuItem } from "@mui/material";
import { CustomeTextField } from "../core/CustomeTextField";
import { IExtension } from "../../interfaces/IExtension";
import { ExtensionSettingTable } from "./ExtensionSettingTable";


interface IProps {
    editExtension: IExtension;
    setEditExtension: Function;
}



export const ExtensionEdit:React.FC<IProps> = ({editExtension,setEditExtension}) => {
    return(
        <>
            <Grid container spacing={2} sx={{mt: 2}}>
                <Grid item sm={12}>
                    <CustomeTextField
                        label="Bezeichnung"
                        attr="extension_name"
                        type="string"
                        object={editExtension}
                        setObject={setEditExtension}
                        required
                    />
                </Grid>
                <Grid item sm={6}>
                    <CustomeTextField
                        label="System-Schlüssel"
                        attr="ExtensionType"
                        type="select"
                        object={editExtension}
                        setObject={setEditExtension}
                        required
                    >{[{id: 1, title: "App"}, {id: 2, title: "Modul"}].map(x =>
                        <MenuItem key={`ExtensionType-${x.id}`} value={x.id}>
                            {x.title}
                        </MenuItem>
                    )}
                    </CustomeTextField>
                </Grid>
                <Grid item sm={6}>
                    <CustomeTextField
                        label="System-Schlüssel"
                        attr="system_key"
                        type="string"
                        object={editExtension}
                        setObject={setEditExtension}
                        required
                    />
                </Grid>

                <Grid item sm={12}>
                    <CustomeTextField
                        label="Preis"
                        attr="selling_price"
                        type="number"
                        object={editExtension}
                        setObject={setEditExtension}
                        required
                    />
                </Grid>
                <Grid item sm={12}>
                    <CustomeTextField
                        label="Hervorheben im Marketplace"
                        attr="isHighlighted"
                        type="boolean"
                        object={editExtension}
                        setObject={setEditExtension}
                        required
                    />
                </Grid>
                <Grid item sm={12}>
                    <CustomeTextField
                        label="URL DiNo"
                        attr="dino_link"
                        type="string"
                        object={editExtension}
                        setObject={setEditExtension}
                        null
                    />
                </Grid>
                <Grid item sm={12}>
                    <CustomeTextField
                        label="HubSpot DiNo"
                        attr="hubspot_link"
                        type="string"
                        object={editExtension}
                        setObject={setEditExtension}
                        null
                    />
                </Grid>
                <Grid item sm={12}>
                    <CustomeTextField
                        label="Beschreibung (HTML)"
                        attr="descriptionHtml"
                        type="string"
                        object={editExtension}
                        setObject={setEditExtension}
                        rows={5}
                    />
                </Grid>
            </Grid>

            {editExtension.id > 0 &&
                <ExtensionSettingTable
                    extension={editExtension}
                    setExtension={setEditExtension}
                />
            }

        </>
    )
}