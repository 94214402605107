import React, { useState } from "react";
import { GenericOverview } from "../components/core/GenericOverview";
import { getEmptyRelease, ReleaseEdit } from "../components/Release/ReleaseEdit";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useFetch } from "../hooks/useFetch";
import { IRelease } from "../interfaces/IRelease";
import { IChangeType } from "../interfaces/IChangeType";
import { Check, Close } from "@mui/icons-material";


export const Release:React.FC = () => {
    const [editRelease,setEditRelease] = useState(getEmptyRelease());
    const [currentArray, setCurrentArray] = useState<IRelease[]>([]);
    //
    const [changeTypeArray,setChangeTypeArray,statusCodeChangeType] = useFetch<IChangeType[]>("/release/change/type/");

    const columns: GridColDef[] = [
        { 
            field: 'id', 
            headerName: 'Nr.', 
            width: 90 
        },
        { 
            field: 'is_disabled', 
            headerName: 'Freigegeben', 
            width: 150,
            renderCell: (params: GridRenderCellParams<any, boolean>) => (params.value) ? <Check/> : <Close/>
        },
        { 
            field: 'releaseAt', 
            headerName: 'Veröffentlichung', 
            width: 150,
            valueGetter: (value) => new Date(value).toLocaleDateString()
        },
        { 
            field: 'title', 
            headerName: 'Version', 
            flex: 1
        },
    ]

    return(
        <GenericOverview
            title="Veröffentlichungen"
            btnTitle="Neue Veröffentlichung"
            columns={columns}
            editObject={editRelease}
            setEditObject={setEditRelease}
            currentArray={currentArray}
            setCurrentArray={setCurrentArray}
            fetchUrl="/release/"
            getEmptyObject={getEmptyRelease}
            enableDialog
            dialogWidth={editRelease.id < 0 ? "md" : "xl"}

        >
            <ReleaseEdit
                key={`relaeseedit-${editRelease.id}`}
                currentRelease={editRelease}
                setCurrentRelease={setEditRelease}
                changeTypeArray={changeTypeArray || []}
            />
        </GenericOverview>
    )
}