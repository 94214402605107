import React, { useState } from 'react';
import {  Box, CssBaseline, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar, Tooltip, Typography } from "@mui/material";
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import { ChevronLeft, ChevronRight, DesignServices, HealthAndSafety, Home, Handshake, Logout, Person, Star, PictureAsPdf, Apartment, Extension, ViewModule, Category, Dataset, Email, WebAsset, Dns, Storage, Diversity2, BrowserUpdated } from '@mui/icons-material';
import AvatarePicture from './AvatarPicture';


const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean; 
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

interface INavElement {
    ListItemKey: string;
    Navigate: string;
    Icon: React.ReactElement;
    ListItemText: string;
    Divider?: boolean;
    isAdmin?: boolean;
}

export interface IProps {
    children?: React.ReactNode;
}


export const NavBar:React.FC<IProps> = (props) => {
    const theme = useTheme();
    const [isSidebarOpen,setIsSidebarOpen] = useState(false);
    const [pageName,setPageName] = useState("Home");
    const navigate = useNavigate();
    const [isAdmin,setIsAdmin] = useState(localStorage.getItem("is_superuser") === "true")
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);


    const naviagtionFunc = (targetUrl:string,pageName:string) => {
        setPageName(pageName);
        navigate(targetUrl);
        setIsSidebarOpen(false);
    }


    const menuArray:INavElement[] = [
        {
            ListItemKey: "dashboard",
            Navigate: "/",
            Icon: <Home/>,
            ListItemText: "Dashboard",
            Divider: true
        },
        {
            ListItemKey: "generale",
            Navigate: "/dino/generale",
            Icon: <Diversity2/>,
            ListItemText: "DiNo Generale",
            isAdmin: true
        },
        {
            ListItemKey: "customers",
            Navigate: "/customers",
            Icon: <Person/>,
            ListItemText: "Kunden",
        },
        {
            ListItemKey: "contracts",
            Navigate: "/contracts",
            Icon: <Star/>,
            ListItemText: "Abonnements",
        },
        {
            ListItemKey: "websites",
            Navigate: "/websites",
            Icon: <WebAsset/>,
            ListItemText: "Webseiten",
        },
        {
            ListItemKey: "DiNo",
            Navigate: "/dino",
            Icon: <Dns/>,
            ListItemText: "DiNo-Instanzen",
            Divider: true,
        },
        {
            ListItemKey: "release",
            Navigate: "/releases",
            Icon: <BrowserUpdated/>,
            ListItemText: "Veröffentlichungen",
            isAdmin: true
        },
        {
            ListItemKey: "subscriptions",
            Navigate: "/subscriptions",
            Icon: <Dataset/>,
            ListItemText: "Pakete",
            isAdmin: true
        },
        {
            ListItemKey: "extension",
            Navigate: "/extension",
            Icon: <Extension/>,
            ListItemText: "Erweiterungen",
            isAdmin: true
        },
        {
            ListItemKey: "systemmail",
            Navigate: "/systemmail",
            Icon: <Email/>,
            ListItemText: "System E-Mail",
            isAdmin: true
        },
        {
            ListItemKey: "server",
            Navigate: "/servers",
            Icon: <Storage/>,
            ListItemText: "Server",
            isAdmin: true
        },
        
    ]

    const generateEntry = () => {

        return menuArray.map(x => {
            if (
                (x.isAdmin === undefined || (x.isAdmin === false || (x.isAdmin === true && isAdmin === true) ))
            ) {
                return (
                    <>
                        <ListItem key={x.ListItemKey} disablePadding sx={{ display: 'block' }}>
                            <ListItemButton onClick={() => naviagtionFunc(x.Navigate,x.ListItemText)  }>
                            <ListItemIcon>{x.Icon}</ListItemIcon>
                            <ListItemText primary={x.ListItemText} />
                            </ListItemButton>
                        </ListItem>
                        {(x.Divider) && <Divider />}
                    </>
                )
            }
        })
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            <AppBar position="fixed" open={isSidebarOpen}>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{
                            marginRight: 5,
                            ...(isSidebarOpen && { display: 'none' }),
                          }}
                        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                    >
                        <MenuIcon />
                    </IconButton>

                    <Typography variant="h6" noWrap component="div">
                    {pageName}
                    </Typography>

                    <Box display='flex' flexGrow={1} />
                    <Typography sx={{mr: 2}}>v0.1</Typography>

                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Einstellungen öffnen">
                            <IconButton onClick={(event: React.MouseEvent<HTMLElement>)  => setAnchorElUser(event.currentTarget)} sx={{ p: 0 }}>
                                <AvatarePicture/>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={() => setAnchorElUser(null)}
                        >
                            <MenuItem onClick={() =>{localStorage.removeItem("token"); window.location.reload(); }} >
                                <Typography textAlign="center">Abmelden</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>



            <Drawer
                variant="permanent" 
                open={isSidebarOpen}
                //onClose={() => setIsSidebarOpen(!isSidebarOpen)}
            >
                <DrawerHeader>
                    <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                        {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <Box
                    sx={{width: 250}}
                    role="presentation"
                >
                    <List>
                        {generateEntry()}
                    </List>
                </Box>
            </Drawer>

            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader />
                {(props.children) && props.children}
            </Box>
        </Box>
    )
}
