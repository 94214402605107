import React from "react";
import { IRelease } from "../../interfaces/IRelease";
import { Grid, TextField } from "@mui/material";
import { CustomeTextField } from "../core/CustomeTextField";

export interface IProps {
    currentRelease: IRelease;
    setCurrentRelease: Function;
}

export const ReleaseForm: React.FC<IProps> = ({ currentRelease, setCurrentRelease }) => {
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <CustomeTextField
                        attr="is_disabled"
                        label="Freigegeben"
                        object={currentRelease}
                        setObject={setCurrentRelease}
                        type="boolean"
                        required
                        disabled={currentRelease.id < 0}
                    />
                </Grid>
                <Grid item xs={5}>
                    <CustomeTextField
                        attr="releaseAt"
                        label="Veröffentlichung am"
                        object={currentRelease}
                        setObject={setCurrentRelease}
                        type="date"
                        required
                    />
                </Grid>
                <Grid item xs={5}>
                    <CustomeTextField
                        attr="title"
                        label="Version"
                        object={currentRelease}
                        setObject={setCurrentRelease}
                        type="string"
                        required
                    />
                </Grid>
                {(currentRelease.id > 0)  &&
                    <Grid item xs={12}>
                        <CustomeTextField
                            attr="descriptionHtml"
                            label="HTML Beschreibung"
                            object={currentRelease}
                            setObject={setCurrentRelease}
                            type="string"
                            rows={5}
                        />
                    </Grid>
                }
            </Grid>
        </>
    )
}