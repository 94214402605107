import React, { useEffect, useState } from "react";
import { Box, Grid, MenuItem, Tab, Tabs } from "@mui/material";
import { CustomeTextField } from "../core/CustomeTextField";
import { ISubscription } from "../../interfaces/ISubscription";
import { IScheduling } from "../../interfaces/IScheduling";
import { CustomTabPanel, a11yProps } from "../core/CustomTabPanel";
import { ExtensionTable } from "./Childs/ExtensionTable";
import { IExtension } from "../../interfaces/IExtension";


interface IProps {
    editSubscription: ISubscription;
    setEditSubscription: Function;
    extensionArray: IExtension[];
    schedulingArray: IScheduling[];
}



export const SubscriptionEdit:React.FC<IProps> = ({editSubscription,setEditSubscription, extensionArray, schedulingArray }) => {
    const [currentPage,setCurrentPage] = useState(0);
    //
    const [assignedExtension,setAssignedExtension] = useState(editSubscription.Assigned_Extension);


    useEffect(() => {
        setEditSubscription({
            ...editSubscription,
            Assigned_Extension: assignedExtension,
        } as ISubscription)
    },[assignedExtension])


    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setCurrentPage(newValue);
    };
    

    return(
        <>
            <Grid container spacing={2} sx={{mt: 2}}>
                <Grid item sm={12}>
                    <CustomeTextField
                        label="Bezeichnung"
                        attr="subscription_name"
                        type="string"
                        object={editSubscription}
                        setObject={setEditSubscription}
                        required
                    />
                </Grid>

                <Grid item sm={4}>
                    <CustomeTextField
                        label="Laufzeit"
                        attr="Scheduling"
                        type="select"
                        object={editSubscription}
                        setObject={setEditSubscription}
                        required
                    >
                        {schedulingArray.map(x =>
                            <MenuItem key={`scheduling-${x.id}`} value={x.id}>{x.scheduling}</MenuItem>
                        )}
                    </CustomeTextField>
                </Grid>

                <Grid item sm={8}>
                    <CustomeTextField
                        label="Preis"
                        attr="selling_price"
                        type="number"
                        object={editSubscription}
                        setObject={setEditSubscription}
                        required
                    />
                </Grid>


                <Grid item sm={4}>
                    <CustomeTextField
                        label="Alles inklusive"
                        attr="isFreeAll"
                        type="boolean"
                        object={editSubscription}
                        setObject={setEditSubscription}
                        required
                    />
                </Grid>

                <Grid item sm={4}>
                    <CustomeTextField
                        label="Module inklusive"
                        attr="free_modules"
                        type="number"
                        object={editSubscription}
                        setObject={setEditSubscription}
                        required
                    />
                </Grid>

                <Grid item sm={4}>
                    <CustomeTextField
                        label="Apps inklusive"
                        attr="free_apps"
                        type="number"
                        object={editSubscription}
                        setObject={setEditSubscription}
                        required
                    />
                </Grid>
            </Grid>


            <Box sx={{ width: '100%', mt: 5 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={currentPage} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Erweiterungen" {...a11yProps(0)} />
                    </Tabs>
                </Box>

                <CustomTabPanel value={currentPage} index={0}>
                    <ExtensionTable
                        assignedExtension={assignedExtension}
                        editSubscription={editSubscription}
                        extensionArray={extensionArray}
                        setAssignedExtension={setAssignedExtension}
                        disableBtn={false}
                    />
                </CustomTabPanel>


            </Box>

        </>
    )
}