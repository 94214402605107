import React, { useEffect, useState } from "react";
import { getFetch, useFetch } from "../hooks/useFetch";
import { IGender } from "../interfaces/IGender";
import { IAcademicTitle } from "../interfaces/IAcademicTitle";
import { IScheduling } from "../interfaces/IScheduling";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { ICompany } from "../interfaces/ICompany";
import { GenericOverview } from "../components/core/GenericOverview";
import { CompanyEdit } from "../components/Customer/CompanyEdit";
import { IUser } from "../interfaces/IUser";
import { ICustomer } from "../interfaces/ICustomer";
import { getIsAdmin } from "../components/core/helper";
import { ISubscription } from "../interfaces/ISubscription";
import { IContract } from "../interfaces/IContract";
import { IWebsite } from "../interfaces/IWebsite";
import { WebsiteEdit } from "../components/Website/WebsiteEdit";
import { IWebVersion } from "../interfaces/IWebVersion";
import { IServer } from "../interfaces/IServer";
import { IMIMEtype } from "../interfaces/IMIMEtype";


const getEmptyWebsite = () => {
    return {
        id: -1,
        Contract: null,
        description: "",
        email_contactfield: "",
        ServerInstance: null,
        isTemplate: false,
        url: "",
        website_title: ""

    } as IWebsite
}




export const Websites:React.FC = () => {
    const isAdmin = getIsAdmin();

    //
    //const [web,setContractArray,statusCodeContractArray] = useFetch<IContract[]>("/website/")
    const [companyArray,setCompanyArray,statusCodeCompanyArray] = useFetch<ICompany[]>("/company/")
    const [contractArray,setContractArray,statusCodeContractArray] = useFetch<IContract[]>("/company/contract/")
    //
    const [genderArray,setGenderArray,statusCodeGenderArray] = useFetch<IGender[]>("/person/gender/");
    const [academicTitleArray,setAcademicTitleArray,statusCodeAcademicTitleArray] = useFetch<IAcademicTitle[]>("/person/academic_title/");
    const [personArray,setPersonArray,statusCodePersonArray] = useFetch<ICustomer[]>("/person/");
    //
    const [mimeTypeArray,setMimeTypeArray,statusCodeMimeType] = useFetch<IMIMEtype[]>("/mimetype/");
    //
    const [serverArray, setServerArray] = useState<IServer[]>([]);
    //
    const [currentArray, setCurrentArray]  = useState<IWebsite[]>([]);
    const [editWebsite,setEditWebsite] = useState(getEmptyWebsite());

    const columns: GridColDef[] = [
        { 
            field: 'id', 
            headerName: 'Nr.', 
            width: 90 
        },
        { 
            field: 'Contract_1',
            headerName: 'Unternehmen', 
            valueGetter: (value, row) => (row.Contract === null) ? "" : companyArray?.find(y => y.id === contractArray?.find(x => x.id === row.Contract)?.Company)?.company_name,
            width: 200 
        },
        { 
            field: 'Contract_2', 
            headerName: 'Vertrag', 
            valueGetter: (value,row) => (row.Contract === null) ? "" : `${contractArray?.find(x => x.id === row.Contract)?.contract_title} / ${contractArray?.find(x => x.id === row.Contract)?.contract_number}` ,
            width: 200 
        },
        { 
            field: 'WebVersions', 
            headerName: 'Version', 
            valueGetter: (value:IWebVersion[]) => (value.length === 0) ? "k.A." : Math.max(...value.map(x => x.version)),
            width: 100 
        },
        { 
            field: 'url', 
            headerName: 'URL', 
            renderCell: (params: GridRenderCellParams<any, string>) => <a  target="_blank" href={`https://${params.value}`}>{params.value}</a>,
            flex: 1
        },
        { 
            field: 'website_title', 
            headerName: 'Webseite', 
            flex: 2
        },
    
    ]

    useEffect(() => {
        if (isAdmin) {
            getFetch("/server/",undefined,setServerArray)
        }
    },[])

    return(
        <GenericOverview
            title={(isAdmin) ? "Webseiten" : "Meine Webseiten"}
            btnTitle="Neuer Webseiten"
            disableBtn={!isAdmin}
            columns={columns}
            editObject={editWebsite}
            setEditObject={setEditWebsite}
            currentArray={currentArray}
            setCurrentArray={setCurrentArray}
            fetchUrl="/website/"
            getEmptyObject={getEmptyWebsite}
            enableDialog
            dialogWidth="md"

        >
            <WebsiteEdit
                editWebsite={editWebsite}
                setEditWebsite={setEditWebsite}
                companyArray={companyArray || []}
                contractArray={contractArray || []}
                websiteArray={currentArray}
                personArray={personArray || []}
                serverArray={serverArray}
                mimeTypeArray={mimeTypeArray || []}
            />
        </GenericOverview>
    )
}