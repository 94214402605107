import React from "react";
import { Alert, Collapse, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { CustomeTextField } from "../core/CustomeTextField";
import { IContract } from "../../interfaces/IContract";
import { IClient } from "../../interfaces/IClient";
import { IClientType } from "../../interfaces/IClientType";
import { getIsAdmin } from "../core/helper";


interface IProps {
    editClient: IClient;
    setEditClient: Function;
    clientTypeArray: IClientType[];
    contractArray: IContract[];
}



export const ClientEdit:React.FC<IProps> = ({editClient,setEditClient, clientTypeArray, contractArray}) => {
    const isAdmin = getIsAdmin();


    return(
        <>
            <Collapse in={editClient.is_locked}>
                <Alert severity="warning">
                    Sie haben die Client-Sperre aktiviert. 
                    <br />Der Client wird {(editClient.locked_at === null) ? <>sofort</> : <>ab dem {new Date(editClient.locked_at).toDateString()}</>} deaktiviert sein.
                </Alert>
            </Collapse>


            <Grid container spacing={2} sx={{mt: 2}}>
                <Grid item sm={12}>
                    <CustomeTextField
                        label="Vertrag"
                        attr="Contract"
                        type="select"
                        object={editClient}
                        setObject={setEditClient}
                        required
                        disabled={!isAdmin}
                    >
                        {contractArray.map(x =>
                            <MenuItem key={`select-contract-${x.id}`} value={x.id}>
                                {x.contract_title}
                            </MenuItem>
                        )}
                    </CustomeTextField>
                </Grid>

                <Grid item sm={6}>
                    <CustomeTextField
                        label="Clienttype"
                        attr="ClientType"
                        type="select"
                        object={editClient}
                        setObject={setEditClient}
                        required
                        disabled={!isAdmin}
                    >
                        {clientTypeArray.map(x =>
                            <MenuItem key={`select-clienttype-${x.id}`} value={x.id}>
                                {x.client_type}
                            </MenuItem>
                        )}
                    </CustomeTextField>
                </Grid>


                <Grid item sm={3}>
                    <CustomeTextField
                        label="Testing"
                        attr="is_testing"
                        type="boolean"
                        object={editClient}
                        setObject={setEditClient}
                        required
                        disabled={!isAdmin}
                    />
                </Grid>

                <Grid item sm={3}>
                    <CustomeTextField
                        label="Registriert"
                        attr="is_registered"
                        type="boolean"
                        object={editClient}
                        setObject={setEditClient}
                        required
                        disabled={editClient.id < 0 || !isAdmin}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} sx={{mt: 2}}>
                <Grid item sm={3}>
                    <CustomeTextField
                        label="Sperren"
                        attr="is_locked"
                        type="boolean"
                        object={editClient}
                        setObject={setEditClient}
                        required
                        disabled={editClient.id < 0 || !isAdmin}
                    />
                </Grid>
                <Grid item sm={9}>
                    <CustomeTextField
                        label="Sperren am (leer, sofort)"
                        attr="locked_at"
                        type="date"
                        object={editClient}
                        setObject={setEditClient}
                        disabled={!editClient.is_locked}
                        null
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} sx={{mt: 2}}>
                <Grid item sm={6}>
                    <TextField
                        fullWidth
                        label="IP"
                        value={editClient.ip}
                        disabled
                        size="small"
                    />
                </Grid>
                <Grid item sm={6}>
                    <TextField
                        fullWidth
                        label="Servername"
                        value={editClient.servername}
                        disabled
                        size="small"
                    />
                </Grid>

                <Grid item sm={12}>
                    <TextField
                        fullWidth
                        label="FQDN"
                        value={editClient.servername_fqdn}
                        disabled
                        size="small"
                    />
                </Grid>

                <Grid item sm={12}>
                    <TextField
                        fullWidth
                        label="Lizenz (wird generiert)"
                        value={editClient.license_key}
                        rows={2}
                        multiline
                        disabled
                    />
                </Grid>
            </Grid>


            <Typography sx={{mt: 2, mb: 1}}>DiNo Verbindung</Typography>
            <Grid container spacing={2}>
                <Grid item sm={12}>
                    <CustomeTextField
                        label="Öffentlicher Server"
                        attr="public_server"
                        type="string"
                        object={editClient}
                        setObject={setEditClient}
                        required
                        disabled={!isAdmin}
                    />
                </Grid>
            </Grid>


            <Typography sx={{mt: 2, mb: 1}}>SMTP-Server</Typography>
            <Grid container spacing={2}>
                <Grid item sm={6}>
                    <CustomeTextField
                        label="Email"
                        attr="email"
                        type="email"
                        object={editClient}
                        setObject={setEditClient}
                        required
                        disabled={!isAdmin}
                    />
                </Grid>
                <Grid item sm={6}>
                    <CustomeTextField
                        label="Passwort"
                        attr="password"
                        type="password"
                        object={editClient}
                        setObject={setEditClient}
                        required
                        disabled={!isAdmin}
                    />
                </Grid>

                <Grid item sm={6}>
                    <CustomeTextField
                        label="SMTP-Server"
                        attr="server"
                        type="string"
                        object={editClient}
                        setObject={setEditClient}
                        required
                        disabled={!isAdmin}
                    />
                </Grid>
                <Grid item sm={4}>
                    <CustomeTextField
                        label="SMTP-Port"
                        attr="port"
                        type="string"
                        object={editClient}
                        setObject={setEditClient}
                        required
                        disabled={!isAdmin}
                    />
                </Grid>
                <Grid item sm={2}>
                    <CustomeTextField
                        label="TLS"
                        attr="tls"
                        type="boolean"
                        object={editClient}
                        setObject={setEditClient}
                        required
                        disabled={!isAdmin}
                    />
                </Grid>
            </Grid>

        </>
    )
}