import React, { useEffect, useState } from "react";
import { uploadFetch, useFetch } from "../hooks/useFetch";
import { DataGrid, GridColDef, GridRowParams } from "@mui/x-data-grid";
import { Alert, Button, Collapse, Dialog, DialogActions, DialogContent, Grid, LinearProgress, Typography } from "@mui/material";
import { IExtension } from "../interfaces/IExtension";
import { ExtensionEdit } from "../components/Extension/ExtensionEdit";




const getEmptyApp = (idExtensionType: number) => {
    return {
        extension_name: "",
        id: -1,
        isHighlighted: false,
        selling_price: 0,
        system_key: "",
        descriptionHtml: "",
        dino_link: null,
        ExtensionType: idExtensionType,
        hubspot_link: null,
        ExtensionSettings: []
    } as IExtension
}

export const columnsApp: GridColDef[] = [
    { 
        field: 'id', 
        headerName: 'Nr.', 
        width: 90 
    },

    { 
        field: 'ExtensionType', 
        headerName: 'Art', 
        valueGetter: (value) => Number(value) === 1 ? "App" : "Modul",
        width: 100 
    },
    { 
        field: 'system_key', 
        headerName: 'System-Schlüssel', 
        width: 200 
    },
    { 
        field: 'selling_price', 
        headerName: 'Preis (netto)', 
        valueGetter: (value) => Number(value).toLocaleString("de-DE", { style: "currency", currency: "EUR" }),
        width: 100 
    },
    { 
        field: 'extension_name', 
        headerName: 'Bezeichnung', 
        flex: 1
    },

]


export const ExtensionOverview:React.FC = () => {
    const [extensionArray,setExtensionArray,statusCodeExtensionArray] = useFetch<IExtension[]>("/extension/");
    //
    const [editExtension,setEditExtension] = useState(getEmptyApp(1));
    const [wasSuccessfully,setWasSuccessfully] = useState(true);
    const [isLoadgin,setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    

    const handleChangeId = (params: GridRowParams<any>) => {
        let testObject = extensionArray?.find(x => x.id === params.id);

        if (testObject === undefined) {
            setEditExtension(getEmptyApp(1))
        } else {
            setEditExtension(testObject);
        }
        setIsOpen(true);
    }

    const handleNew = () => {
        setEditExtension(getEmptyApp(1));
        setIsOpen(true);
    }


    const handleAfterSave = (savedObject:IExtension) => {
        if (extensionArray !== undefined) {
            //setFeedback({ open: true,message: "Gespeichert!", severity: "success" } )
        
            let testObject = extensionArray.find(x => x.id === savedObject.id);

            if (testObject === undefined) {
                setExtensionArray([
                    ...extensionArray,
                    savedObject
                ])
            }
            else {
                setExtensionArray([
                    ...extensionArray.map(x => x.id === savedObject.id ? savedObject : x)
                ])
            }

            setIsOpen(false);
        }
    }

    
    const handleSave = () => {
        setWasSuccessfully(true);
        if (editExtension.id < 0) {
            uploadFetch("/extension/",true,editExtension,handleAfterSave,setWasSuccessfully,()=>{},setIsLoading)
        } else {
            uploadFetch(`/extension/${editExtension.id}`,false,editExtension,handleAfterSave,setWasSuccessfully,()=>{},setIsLoading)
        }
    }

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        handleSave();
    }

    useEffect(() => {
        if (!wasSuccessfully) {
            //setFeedback({ open: true,message: "Ein fehler ist aufgetretten!", severity: "error" } )
            setIsLoading(false);
        }
    },[wasSuccessfully])


    if (
        (statusCodeExtensionArray !== null && statusCodeExtensionArray !== 200)
    ) { return <Alert severity="error">Fehler!</Alert> }

    else if (
        extensionArray === undefined
    ) { return <LinearProgress/> }

    else {
        return(
            <>
                {(isOpen) &&
                    <Dialog open onClose={() => setIsOpen(false)}>
                        <form onSubmit={handleSubmit}>
                        <DialogContent>
                            <ExtensionEdit
                                key={`app-${editExtension.id}`}
                                editExtension={editExtension}
                                setEditExtension={setEditExtension}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button sx={{float: "right", ml: 2}} type="submit" variant="contained" disabled={isLoadgin}>Speichern</Button>
                            <Button sx={{float: "right"}} onClick={() => setIsOpen(false)} variant="outlined">Abbruch</Button>
                        </DialogActions>
                        </form>
                    </Dialog>
                }

                <Typography variant="h5">
                    Erweiterungen
                    <Button sx={{float: "right"}} variant="outlined" onClick={handleNew}>Neue Erweiterung
                    </Button>
                </Typography>

                <Collapse sx={{mt: 1}} in={isLoadgin}>
                    <LinearProgress/>
                </Collapse>

                {/* <CustomSnackbar feedback={feedback} setFeedback={setFeedback}/> */}


                <Grid container spacing={2} sx={{mt: 2}}>
                    <Grid item sm={12}>
                        <DataGrid
                            rows={extensionArray}
                            columns={columnsApp}
                            //getRowId={(row) => row.id}
                            onRowClick={(params,event,details) => handleChangeId(params)}
                            autoHeight
                        />
                    </Grid>
                    <Grid item sm={(isOpen) ? 6 : 0}>
                        <Collapse in={isOpen}>
                            <form onSubmit={handleSubmit}>
                                <Typography variant="h6">
                                    Leistung

                                </Typography>

                            </form>
                        </Collapse>
                    </Grid>
                </Grid>
            </>
        )
    }


}