import React, { useEffect, useState } from "react";
import { IExtension } from "../../interfaces/IExtension";
import { Box, Button, Table, TableBody, TableHead, Typography } from "@mui/material";
import { ExtensionSettingRow } from "./ExtensionSettingRow";
import { IExtensionSetting } from "../../interfaces/IExtensionSetting";
import { getTmpId } from "../core/helper";

interface IProps {
    extension: IExtension;
    setExtension: Function;
}


export const ExtensionSettingTable:React.FC<IProps> = ({extension,setExtension}) => {
    const [settingArray,setSettingArray] = useState(extension.ExtensionSettings);

    useEffect(() => {
        setExtension({
            ...extension,
            ExtensionSettings: settingArray
        })
    },[settingArray])

    const onNew = () => {
        setSettingArray([
            ...settingArray,
            {id: getTmpId(settingArray), extension_key: "", extension_value: "", Extension: extension.id} as IExtensionSetting
        ])
    }

    return(
        <Box sx={{mt: 5}}>
            <Typography>
                Einstellungen
                <Button variant="outlined" sx={{float: "right"}} onClick={onNew}>Neu</Button>
            </Typography>
            <Table size="small">
                <TableBody>
                    {settingArray.map(set =>
                        <ExtensionSettingRow
                            extensionSettingArray={settingArray}
                            setExtensionSettingArray={setSettingArray}
                            setting={set}
                        />
                    )}
                </TableBody>
            </Table>
        </Box>
    )
}