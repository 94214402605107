import React, { useEffect, useState } from "react";
import { IChangeType } from "../../interfaces/IChangeType";
import { IChange } from "../../interfaces/IChange";
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, IconButton, MenuItem } from "@mui/material";
import { Delete, ExpandMore } from "@mui/icons-material";
import { CustomeTextField } from "../core/CustomeTextField";

export interface IProps {
    currentChange: IChange;
    changeArray: IChange[];
    setChangeArray: Function;
    changeTypeArray: IChangeType[];
}


export const ChangeAccordion:React.FC<IProps> = ({currentChange,changeArray,setChangeArray,changeTypeArray}) => {
    const [editChange,setEditChange] = useState(currentChange);

    const onRemove = () => {
        setChangeArray([
            ...changeArray.filter(x => x.id !== editChange.id)
        ])
    }

    useEffect(() => {
        setChangeArray([
            ...changeArray.map(x => x.id === editChange.id ? editChange : x)
        ])
    },[editChange])
    
    return(
        <>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1-content"
                >
                    {changeTypeArray.find(x => x.id === editChange.ChangeType)?.changeType}: {editChange.title}
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Button sx={{float: "right"}} variant="outlined" onClick={onRemove}>Entfernen</Button>
                        </Grid>
                        <Grid item xs={2}>
                            <CustomeTextField
                                attr="isHighlighted"
                                label="Highlight"
                                object={editChange}
                                setObject={setEditChange}
                                type="boolean"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <CustomeTextField
                                attr="ChangeType"
                                label="Art"
                                object={editChange}
                                setObject={setEditChange}
                                type="select"
                            >
                                {changeTypeArray.map(changeType =>
                                    <MenuItem key={`select-changetype-${changeType.id}`} value={changeType.id}>
                                        {changeType.changeType}
                                    </MenuItem>
                                )}
                            </CustomeTextField>
                        </Grid>
                        <Grid item xs={8}>
                            <CustomeTextField
                                attr="title"
                                label="Titel"
                                object={editChange}
                                setObject={setEditChange}
                                type="string"
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomeTextField
                                attr="dinoAcademyLink"
                                label="DiNo Academy Link"
                                object={editChange}
                                setObject={setEditChange}
                                type="string"
                                null
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomeTextField
                                attr="descriptionHtml"
                                label="HTML Beschreibung"
                                object={editChange}
                                setObject={setEditChange}
                                type="string"
                                rows={5}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </>
    )
}