import React, { useState } from "react"
import { IRelease } from "../../interfaces/IRelease"
import { IChangeType } from "../../interfaces/IChangeType";
import { ReleaseForm } from "./ReleaseForm";
import { Grid } from "@mui/material";
import { ChangeSite } from "./ChangeSite";

export interface IProps {
    currentRelease: IRelease;
    setCurrentRelease: Function;
    changeTypeArray: IChangeType[];
}



export const getEmptyRelease = () => {
    return {
        created_at: "",
        descriptionHtml: "",
        id: -1,
        is_disabled: false,
        releaseAt: "",
        title: "",
        changes: []
    } as IRelease
}


export const ReleaseEdit: React.FC<IProps> = ({currentRelease, setCurrentRelease, changeTypeArray}) => {
    const [orgRelease,setOrgRelease] = useState(currentRelease);

    const getHtmlView = () => {
        return(
            <>
                <div dangerouslySetInnerHTML={{__html : currentRelease.descriptionHtml}} />
                {currentRelease.changes.map(change =>
                    <div dangerouslySetInnerHTML={{__html : change.descriptionHtml}} />
                )}
            </>
        )
    }

    if (orgRelease.is_disabled == true) { 
        return(
            getHtmlView()
        )
    }
    else if (currentRelease.id < 0) {
        return(
            <>
                <ReleaseForm
                    currentRelease={currentRelease}
                    setCurrentRelease={setCurrentRelease}
                />
            </>
        )
    } else {
        return(
            <>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <ReleaseForm
                            currentRelease={currentRelease}
                            setCurrentRelease={setCurrentRelease}
                        />
                        <ChangeSite
                            currentRelease={currentRelease}
                            setCurrentRelease={setCurrentRelease}
                            changeTypeArray={changeTypeArray}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        {getHtmlView()}
                    </Grid>
                </Grid>
            </>
        )
    }

}