import { DataGrid, GridColDef, GridRowParams } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { uploadFetch, useFetch } from "../../hooks/useFetch";
import { Alert, Box, Breakpoint, Button, Collapse, Dialog, DialogContent, Grid, LinearProgress, Typography } from "@mui/material";



interface IProps {
    title: string;
    btnTitle: string;
    editObject: any;
    setEditObject: Function;
    currentArray: any[];
    setCurrentArray: Function;
    columns: GridColDef[];
    fetchUrl:string;
    children: React.ReactElement;
    getEmptyObject: Function;
    enableDialog?: boolean;
    dialogWidth?: Breakpoint
    disableBtn?: boolean;
}


export const GenericOverview:React.FC<IProps> = ({
    title, btnTitle, editObject, setEditObject,  currentArray, setCurrentArray, columns,fetchUrl,children,getEmptyObject, 
    enableDialog, dialogWidth, disableBtn
}) => {
    const [localCurrentArray,setLocalCurrentArray,statusCode] = useFetch<any[]>(fetchUrl);
    //
    //const [editObject,setEditObject] = useState(getEmptyObject());
    const [wasSuccessfully,setWasSuccessfully] = useState(true);
    const [isLoadgin,setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);



    const handleChangeId = (params: GridRowParams<any>) => {
        let testObject = currentArray?.find(x => x.id === params.id);

        if (testObject === undefined) {
            setEditObject(getEmptyObject())
        } else {
            setEditObject(testObject);
        }
        setIsOpen(true);
    }

    const handleNew = () => {
        setEditObject(getEmptyObject());
        setIsOpen(true);
    }


    const handleAfterSave = (savedObject:any) => {
        if (currentArray !== undefined) {
            //setFeedback({ open: true,message: "Gespeichert!", severity: "success" } )
        
            let testObject = currentArray.find(x => x.id === savedObject.id);

            if (testObject === undefined) {
                setCurrentArray([
                    savedObject,
                    ...currentArray
                ])
            }
            else {
                setCurrentArray([
                    ...currentArray.map(x => x.id === savedObject.id ? savedObject : x)
                ])
            }

            setIsOpen(false);
        }
    }

    
    const handleSave = () => {
        setWasSuccessfully(true);
        if (editObject.id < 0) {
            uploadFetch(fetchUrl,true,editObject,handleAfterSave,setWasSuccessfully,()=>{},setIsLoading)
        } else {
            uploadFetch(`${fetchUrl}${editObject.id}`,false,editObject,handleAfterSave,setWasSuccessfully,()=>{},setIsLoading)
        }
    }

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        handleSave();
    }

    useEffect(() => {
        if (localCurrentArray !== undefined) {
            setCurrentArray([
                ...localCurrentArray
            ])
        }
    },[localCurrentArray])

    useEffect(() => {
        if (!wasSuccessfully) {
            //setFeedback({ open: true,message: "Ein fehler ist aufgetretten!", severity: "error" } )
            setIsLoading(false);
        }
    },[wasSuccessfully])


    if (
        (statusCode !== null && statusCode !== 200)
    ) { return <Alert severity="error">Fehler!</Alert> }

    else if (
        localCurrentArray === undefined
    ) { return <LinearProgress/> }

    else {
        return(
            <>
                <Dialog open={isOpen && enableDialog !== undefined && enableDialog === true} maxWidth={(dialogWidth === undefined) ? "sm" : dialogWidth} fullWidth>
                    <DialogContent>
                            <form onSubmit={handleSubmit}>

                                <Typography variant="h6">
                                    {title}
                                </Typography>
                                {children}

                                {(disableBtn === undefined || disableBtn === false)
                                    ?
                                        <Box sx={{mt: 2, mb: 2}}>
                                            <Button sx={{float: "right", ml: 2}} type="submit" variant="contained" disabled={isLoadgin}>Speichern</Button>
                                            <Button sx={{float: "right"}} onClick={() => setIsOpen(false)} variant="outlined">Abbruch</Button>
                                        </Box>
                                    :
                                        <Box sx={{mt: 2, mb: 2}}>
                                            <Button sx={{float: "right"}} onClick={() => setIsOpen(false)} variant="contained">Okay</Button>
                                        </Box>
                                }

                            </form>
                    </DialogContent>

                </Dialog>


                <Typography variant="h5">
                    {title}
                    {(disableBtn === undefined || disableBtn === false) && <Button sx={{float: "right"}} variant="outlined" onClick={handleNew}>{btnTitle}</Button>}
                </Typography>

                <Collapse sx={{mt: 1}} in={isLoadgin}>
                    <LinearProgress/>
                </Collapse>

                {/* <CustomSnackbar feedback={feedback} setFeedback={setFeedback}/> */}


                <Grid container spacing={2} sx={{mt: 2}}>
                    <Grid item sm={(isOpen && (enableDialog === undefined || enableDialog === false)) ? 6 : 12}>
                        <DataGrid
                            rows={currentArray}
                            columns={columns}
                            //getRowId={(row) => row.id}
                            onRowClick={(params,event,details) => handleChangeId(params)}
                            autoHeight
                        />
                    </Grid>
                    <Grid item sm={(isOpen && (enableDialog === undefined || enableDialog === false)) ? 6 : 0}>
                        <Collapse in={(isOpen && (enableDialog === undefined || enableDialog === false))}>
                            <form onSubmit={handleSubmit}>
                                <Typography variant="h6">
                                    Leistung
                                    <Button sx={{float: "right", ml: 2}} type="submit" variant="contained" disabled={isLoadgin}>Speichern</Button>
                                    <Button sx={{float: "right"}} onClick={() => setIsOpen(false)} variant="outlined">Abbruch</Button>
                                </Typography>
                                {children}
                            </form>
                        </Collapse>
                    </Grid>
                </Grid>
            </>
        )
    }


}