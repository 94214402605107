import React, { useEffect, useState } from "react";
import { uploadFetch, useFetch } from "../hooks/useFetch";
import { DataGrid, GridColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import { Alert, Button, Collapse, Grid, LinearProgress, Typography } from "@mui/material";
import { GenericOverview } from "../components/core/GenericOverview";
import { ISubscription } from "../interfaces/ISubscription";
import { IScheduling } from "../interfaces/IScheduling";
import { SubscriptionEdit } from "../components/Subscription/SubscriptionEdit";
import { Check, Close } from "@mui/icons-material";
import { IUser } from "../interfaces/IUser";
import { ICustomer } from "../interfaces/ICustomer";
import { IExtension } from "../interfaces/IExtension";




const getEmptySubscription = () => {
    return {
        id: -1,
        Assigned_Extension: [],
        description: "",
        free_apps: 0,
        free_modules: 0,
        isFreeAll: false,
        Scheduling: 1,
        selling_price: 0,
        subscription_name: ""
    } as ISubscription
}


export const Subscriptions:React.FC = () => {
    const [extensionArray,setExtensionArray,statusCodeExtensionArray] = useFetch<IExtension[]>("/extension/");
    const [schedulingArray,setSchedulingArray,statuCodeSchedulingArray] = useFetch<IScheduling[]>("/subscription/scheduling");
    const [subscriptionArray,setSubscriptionArray,statuCodeSubscriptionArray] = useFetch<ISubscription[]>("/subscription/");
    //
    const [currentArray, setCurrentArray]  = useState<ISubscription[]>([]);
    const [editSubscription,setEditSubscription] = useState(getEmptySubscription());

    const columns: GridColDef[] = [
        { 
            field: 'id', 
            headerName: 'Nr.', 
            width: 90 
        },
        { 
            field: 'Scheduling', 
            headerName: 'Laufzeit', 
            valueGetter: (value) => schedulingArray?.find(x => x.id === value)?.scheduling,
            width: 100 
        },
        { 
            field: 'free_modules', 
            headerName: 'Module inkl.', 
            width: 100 
        },
        { 
            field: 'free_apps', 
            headerName: 'Apps inkl.', 
            width: 100 
        },
        { 
            field: 'isFreeAll', 
            headerName: 'Alles inklusive', 
            width: 150 ,
            renderCell: (params: GridRenderCellParams<any, boolean>) => (params.value) ? <Check/> : <Close/>
        },
        { 
            field: 'Assigned_Module', 
            headerName: 'Zug. Module', 
            renderCell: (params: GridRenderCellParams<any, number[]>) => (params.value?.length),
            width: 100 
        },
        { 
            field: 'Assigned_Apps', 
            headerName: 'Zug. Apps', 
            renderCell: (params: GridRenderCellParams<any, number[]>) => (params.value?.length),
            width: 100 
        },
        { 
            field: 'selling_price', 
            headerName: 'Preis (netto)', 
            valueGetter: (value) => Number(value).toLocaleString("de-DE", { style: "currency", currency: "EUR" }),
            width: 100 
        },
        { 
            field: 'subscription_name', 
            headerName: 'Titel', 
            flex: 1
        }
    
    ]

    return(
        <GenericOverview
            title="Pakete"
            btnTitle="Neues Paket"
            columns={columns}
            editObject={editSubscription}
            setEditObject={setEditSubscription}
            currentArray={currentArray}
            setCurrentArray={setCurrentArray}
            fetchUrl="/subscription/"
            getEmptyObject={getEmptySubscription}
            enableDialog
            dialogWidth="md"

        >
            <SubscriptionEdit
                key={`subscription-${editSubscription.id}`}
                editSubscription={editSubscription}
                setEditSubscription={setEditSubscription}
                extensionArray={extensionArray || []}
                schedulingArray={schedulingArray || []}
            />
        </GenericOverview>
    )

}