import React, { useState } from "react";
import { useFetch } from "../hooks/useFetch";
import { IGender } from "../interfaces/IGender";
import { IAcademicTitle } from "../interfaces/IAcademicTitle";
import { IScheduling } from "../interfaces/IScheduling";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { ICompany } from "../interfaces/ICompany";
import { GenericOverview } from "../components/core/GenericOverview";
import { CompanyEdit } from "../components/Customer/CompanyEdit";
import { IUser } from "../interfaces/IUser";
import { ICustomer } from "../interfaces/ICustomer";
import { getIsAdmin } from "../components/core/helper";
import { ISubscription } from "../interfaces/ISubscription";
import { IContract } from "../interfaces/IContract";
import { ContractEdit } from "../components/Contract/ContractEdit";
import { getEmptyContract } from "../components/Customer/Childs/ContractTable";
import { IExtension } from "../interfaces/IExtension";



export const Contracts:React.FC = () => {
    const isAdmin = getIsAdmin();

    const [userArray,setUserArray,statusCodeUserArray] = useFetch<IUser[]>("/user/")
    //
    const [companyArray,setCompanyArray,statusCodeCompanyArray] = useFetch<ICompany[]>("/company/")

    //
    const [extensionArray,setExtensionArray,statusCodeExtensionArray] = useFetch<IExtension[]>("/extension/");
    const [schedulingArray,setSchedulingArray,statuCodeSchedulingArray] = useFetch<IScheduling[]>("/subscription/scheduling");
    const [subscriptionArrray,setSubscriptionArray,statuCodesubscription] = useFetch<ISubscription[]>("/subscription/");
    //
    const [currentArray, setCurrentArray]  = useState<IContract[]>([]);
    const [editContract,setEditContract] = useState(getEmptyContract(-1));

    const columns: GridColDef[] = [
        { 
            field: 'id', 
            headerName: 'Nr.', 
            width: 90 
        },

        {
            headerName: "Vertragsnummer",
            field: "contract_number",
            width: 200
        },
        { 
            field: 'Scheduling', 
            headerName: 'Laufzeit', 
            valueGetter: (value) => schedulingArray?.find(x => x.id === value)?.scheduling,
            width: 120 
        },
        { 
            field: 'selling_price', 
            headerName: 'Preis (netto)', 
            valueGetter: (value) => Number(value).toLocaleString("de-DE", { style: "currency", currency: "EUR" }),
            width: 120 
        },
        {
            headerName: "Von",
            field: "active_at",
            width: 120,
            valueGetter: (value) => new Date(value).toLocaleDateString(),
        },

        {
            headerName: "Bis",
            field: "closed_at",
            width: 120,
            valueGetter: (value) => (value == null) ? "-" : new Date(value).toLocaleDateString(),
        },
        {
            headerName: "Unternehmen",
            field: "Company",
            flex: 1,
            valueGetter: (value) => companyArray?.find(x => x.id === value)?.company_name,
        },
        {
            headerName: "Vertrag",
            field: "contract_title",
            flex: 2
        }


    
    ]

    return(
        <GenericOverview
            title={(isAdmin) ? "Abonnements" : "Meine Abonnements"}
            btnTitle="Neues Abonnement"
            disableBtn={!isAdmin}
            columns={columns}
            editObject={editContract}
            setEditObject={setEditContract}
            currentArray={currentArray}
            setCurrentArray={setCurrentArray}
            fetchUrl="/company/contract/"
            getEmptyObject={() => getEmptyContract(-1)}
            enableDialog
            dialogWidth="md"

        >
            <ContractEdit
                editContract={editContract}
                setEditContract={setEditContract}
                extensionArray={extensionArray || []}
                schedulingArray={schedulingArray || []}
                subscriptionArray={subscriptionArrray || []}
            />
        </GenericOverview>
    )
}