import React, { useState } from "react";
import { useFetch } from "../hooks/useFetch";
import { IGender } from "../interfaces/IGender";
import { IAcademicTitle } from "../interfaces/IAcademicTitle";
import { IScheduling } from "../interfaces/IScheduling";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { ICompany } from "../interfaces/ICompany";
import { GenericOverview } from "../components/core/GenericOverview";
import { CompanyEdit } from "../components/Customer/CompanyEdit";
import { IUser } from "../interfaces/IUser";
import { ICustomer } from "../interfaces/ICustomer";
import { getIsAdmin } from "../components/core/helper";
import { ISubscription } from "../interfaces/ISubscription";
import { IContract } from "../interfaces/IContract";
import { IServerType } from "../interfaces/IServerType";
import { IServer } from "../interfaces/IServer";
import { ICompanyLeadStage } from "../interfaces/ICompanyLeadStage";
import { Box } from "@mui/material";
import { ICompanyLeadSource } from "../interfaces/ICompanyLeadSource";
import { IExtension } from "../interfaces/IExtension";


const getEmptyCompany = () => {
    return {
        id: -1,
        ci_color_primary: "#ffffff",
        ci_color_secondary: "#ffffff",
        ci_color_text: "#ffffff",
        company_name: "",
        Contracts: [],
        customer_number_lextorbyte: "",
        customer_number_kemto: "",
        email: "",
        homepage: "",
        logo: "",
        logo_mime: "",
        favicon: "",
        phone_number: "",
        Postcode: null,
        street: "",
        street_nr: "",
        tax_number: "",
        Users: [],
        Customers: [],
        vat_number: "",
        fax_number: "",
        gdpr_html: "",
        imprint_html: "",
        CompanyLeadSource: 1,
        CompanyLeadStage: 1,
    } as ICompany
}




export const Customers:React.FC = () => {
    const isAdmin = getIsAdmin();

    const [userArray,setUserArray,statusCodeUserArray] = useFetch<IUser[]>("/user/")
    //
    const [contractArray,setContractArray,statusCodeContractArray] = useFetch<IContract[]>("/company/contract/")
    //
    const [leadeStage,setLeadeStage,statusCodeLeadeStage] = useFetch<ICompanyLeadStage[]>("/company/lead/stage/")
    const [leadeSource,setLeadeSource,statusCodeleadeSource] = useFetch<ICompanyLeadSource[]>("/company/lead/source/")
    //
    const [genderArray,setGenderArray,statusCodeGenderArray] = useFetch<IGender[]>("/person/gender/");
    const [academicTitleArray,setAcademicTitleArray,statusCodeAcademicTitleArray] = useFetch<IAcademicTitle[]>("/person/academic_title/");
    const [personArray,setPersonArray,statusCodePersonArray] = useFetch<ICustomer[]>("/person/");
    //
    const [serverArray, setServerArray, statusCodeServer] = useFetch<IServer[]>("/server/");
    const [serverTypeArray, setServerTypeArray, statusCodeServerType] = useFetch<IServerType[]>("/server/type/");
    //
    const [extensionArray,setExtensionArray,statusCodeExtensionArray] = useFetch<IExtension[]>("/extension/");

    const [schedulingArray,setSchedulingArray,statuCodeSchedulingArray] = useFetch<IScheduling[]>("/subscription/scheduling");
    const [subscriptionArrray,setSubscriptionArray,statuCodesubscription] = useFetch<ISubscription[]>("/subscription/");
    //
    const [currentArray, setCurrentArray]  = useState<ICompany[]>([]);
    const [editSubscription,setEditSubscription] = useState(getEmptyCompany());


    const tsxCurrentStateDot = (currentClient: ICompany) : React.ReactNode => {
        //                 Dunkelgrün  Hellgrün     Hellrot     Rot         Gelb        Grau
        let currentColor : "#228B22" | "#99c58f" | "#fc9292" | "#D22B2B" | "#e6e600" | "#c9c9c5" = "#c9c9c5";
    

        if (currentClient.CompanyLeadStage === 1 || currentClient.CompanyLeadStage === 12) {
            currentColor = "#c9c9c5";
        }
        else if (currentClient.CompanyLeadStage === 2) {
            currentColor = "#e6e600";
        }
        else if (currentClient.CompanyLeadStage in [3,4,5,6]) {
            currentColor = "#99c58f";
        }
        else if (currentClient.CompanyLeadStage == 7) {
            currentColor = "#228B22";
        }
        else if (currentClient.CompanyLeadStage === 8) {
            currentColor = "#fc9292";
        }
        else {
            currentColor = "#D22B2B";
        }

        return (
            <Box sx={{mt: 1}}>
                <span style={{
                        height: 25,
                        width: 25,
                        backgroundColor: currentColor,
                        borderRadius: "50%",
                        display: "inline-block",
                    }}
                >
                </span>
            </Box>
        )
    }


    const columns: GridColDef[] = [
        { 
            field: 'id', 
            headerName: 'Nr.', 
            width: 90 
        },
        { 
            field: 'state', 
            headerName: 'Status', 
            width: 90,
            //valueGetter: (value,row) => getValueState(row),
            renderCell: (params: GridRenderCellParams<ICompany, undefined>) => tsxCurrentStateDot(params.row)
        },  
        { 
            field: 'CompanyLeadStage', 
            headerName: 'Stage', 
            width: 100,
            renderCell: (params: GridRenderCellParams<any, number>) =>  String(leadeStage?.find(x =>x.id === params.value)?.companyLeadStage)
        },
        { 
            field: 'created_at', 
            headerName: 'Erstellt', 
            width: 180,
            renderCell: (params: GridRenderCellParams<any, string>) =>  new Date(String(params.value)).toLocaleString()
        },

        
        { 
            field: 'customer_number_lextorbyte', 
            headerName: 'Kundennummer', 
            width: 150 
        },
        { 
            field: 'phone_number', 
            headerName: 'Telefon', 
            width: 150 
        },
        { 
            field: 'email', 
            headerName: 'E-Mail', 
            width: 150 
        },
        { 
            field: 'company_name', 
            headerName: 'Firma', 
            flex: 1
        },
    
    ]

    return(
        <GenericOverview
            title={(isAdmin) ? "Kunden" : "Meine Unternehmen"}
            btnTitle="Neuer Kunde"
            disableBtn={!isAdmin}
            columns={columns}
            editObject={editSubscription}
            setEditObject={setEditSubscription}
            currentArray={currentArray}
            setCurrentArray={setCurrentArray}
            fetchUrl="/company/"
            getEmptyObject={getEmptyCompany}
            enableDialog
            dialogWidth="xl"

        >
            <CompanyEdit
                editCompany={editSubscription}
                setEditCompany={setEditSubscription}
                userArray={userArray || []}
                setUserArray={setUserArray}
                customerArray={personArray || []}
                setCustomerArray={setPersonArray}
                contractArray={contractArray || []}
                setContractArray={setContractArray}
                academicTitleArray={academicTitleArray || []}
                genderArray={genderArray || []}
                extensionArray={extensionArray || []}
                schedulingArray={schedulingArray || []}
                subscriptionArray={subscriptionArrray || []}

                serverArray={serverArray || []}
                setServerArray={setServerArray}
                serverTypeArray={serverTypeArray || []}

                leadSourceArray={leadeSource || []}
                leadStageArray={leadeStage || []}
            />
        </GenericOverview>
    )
}