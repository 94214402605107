import React, { useEffect, useState } from "react";
import { IExtensionSetting } from "../../interfaces/IExtensionSetting";
import { IconButton, TableCell, TableRow } from "@mui/material";
import { CustomeTextField } from "../core/CustomeTextField";
import { Delete } from "@mui/icons-material";


interface IProps {
    setting: IExtensionSetting;
    extensionSettingArray: IExtensionSetting[];
    setExtensionSettingArray: Function;
}


export const ExtensionSettingRow:React.FC<IProps> = ({setting,extensionSettingArray,setExtensionSettingArray}) => {
    const [editSetting,setEditSetting] = useState(setting);

    useEffect(() => {
        setExtensionSettingArray([
            ...extensionSettingArray.map(x =>
                x.id === editSetting.id ? editSetting : x
            )
        ])
    },[editSetting])

    return(
        <>
            <TableRow key={`extensionsetting-${setting.id}`}>
                <TableCell>
                    <CustomeTextField 
                        label="Key"
                        attr="extension_key"
                        object={editSetting}
                        setObject={setEditSetting}
                        type="string"
                        required
                    />
                </TableCell>
                <TableCell>
                    <CustomeTextField 
                        label="Wert"
                        attr="extension_value"
                        object={editSetting}
                        setObject={setEditSetting}
                        type="string"
                    />
                </TableCell>
                <TableCell>
                    <IconButton>
                        <Delete/>
                    </IconButton>
                </TableCell>
            </TableRow>
        </>
    )
}