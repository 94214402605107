import React from "react";
import { Grid, MenuItem, TextField } from "@mui/material";
import { CustomeTextField } from "../core/CustomeTextField";
import { IServer } from "../../interfaces/IServer";
import { IServerType } from "../../interfaces/IServerType";
import { IContract } from "../../interfaces/IContract";


interface IProps {
    editServer: IServer;
    setEditServer: Function;
    serverTypeArray: IServerType[];
    contractArray: IContract[];
}



export const ServerEdit:React.FC<IProps> = ({editServer,setEditServer, serverTypeArray, contractArray}) => {
    return(
        <>
            <Grid container spacing={2} sx={{mt: 2}}>
                <Grid item sm={12}>
                    <CustomeTextField
                        label="Vertrag"
                        attr="Contract"
                        type="select"
                        object={editServer}
                        setObject={setEditServer}
                        required
                    >
                        {contractArray.map(x =>
                            <MenuItem key={`select-contract-${x.id}`} value={x.id}>
                                {x.contract_title}
                            </MenuItem>
                        )}
                    </CustomeTextField>
                </Grid>

                <Grid item sm={12}>
                    <CustomeTextField
                        label="Serverart"
                        attr="ServerType"
                        type="select"
                        object={editServer}
                        setObject={setEditServer}
                        required
                    >
                        {serverTypeArray.map(x =>
                            <MenuItem key={`select-servertype-${x.id}`} value={x.id}>
                                {x.server_type}
                            </MenuItem>
                        )}
                    </CustomeTextField>
                </Grid>
                <Grid item sm={6}>
                    <CustomeTextField
                        label="IP"
                        attr="ip"
                        type="string"
                        object={editServer}
                        setObject={setEditServer}
                    />
                </Grid>
                <Grid item sm={6}>
                    <CustomeTextField
                        label="Server"
                        attr="servername"
                        type="string"
                        object={editServer}
                        setObject={setEditServer}
                    />
                </Grid>

                <Grid item sm={12}>
                    <CustomeTextField
                        label="FQDN"
                        attr="servername_fqdn"
                        type="string"
                        object={editServer}
                        setObject={setEditServer}
                    />
                </Grid>

                <Grid item sm={12}>
                    <TextField
                        fullWidth
                        label="API-Key (wird generiert)"
                        value={editServer.api_key}
                        rows={2}
                        multiline
                        disabled
                    />
                </Grid>
            </Grid>

        </>
    )
}