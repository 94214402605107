import React, { useEffect, useRef, useState } from "react";
import { ICompany } from "../../interfaces/ICompany";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Collapse, Grid, MenuItem, Tab, Tabs, Typography } from "@mui/material";
import { CustomeTextField } from "../core/CustomeTextField";
import PostcodeSearchV2 from "../core/PostcodeSearchV2";
import { CustomTabPanel, a11yProps } from "../core/CustomTabPanel";
import { IUser } from "../../interfaces/IUser";
import { UserTable } from "./Childs/UserTable";
import { ICustomer } from "../../interfaces/ICustomer";
import { PersonTable } from "./Childs/PersonTable";
import { IGender } from "../../interfaces/IGender";
import { IAcademicTitle } from "../../interfaces/IAcademicTitle";
import { getIsAdmin } from "../core/helper";
import { ContractTable } from "./Childs/ContractTable";
import { IContract } from "../../interfaces/IContract";
import { IScheduling } from "../../interfaces/IScheduling";
import { ISubscription } from "../../interfaces/ISubscription";
import { IServerType } from "../../interfaces/IServerType";
import { ServerTable } from "./Childs/ServerTable";
import { IServer } from "../../interfaces/IServer";
import { UploadFile } from "@mui/icons-material";
import { HTMLEditor } from "../core/HTMLEditor";
import { ICompanyLeadStage } from "../../interfaces/ICompanyLeadStage";
import { ICompanyLeadSource } from "../../interfaces/ICompanyLeadSource";
import { IExtension } from "../../interfaces/IExtension";


interface IProps {
    editCompany: ICompany;
    setEditCompany: Function;
    userArray: IUser[];
    setUserArray: Function;
    customerArray: ICustomer[];
    setCustomerArray: Function;
    contractArray: IContract[];
    setContractArray: Function;
    genderArray: IGender[];
    academicTitleArray: IAcademicTitle[]
    extensionArray : IExtension[];
    schedulingArray: IScheduling[];
    subscriptionArray: ISubscription[];

    serverArray: IServer[];
    setServerArray: Function;
    serverTypeArray: IServerType[];

    leadStageArray: ICompanyLeadStage[];
    leadSourceArray: ICompanyLeadSource[];

    disableChilds?: boolean;

}


export const CompanyEdit: React.FC<IProps> = ({ 
    editCompany, setEditCompany,
    userArray, setUserArray, customerArray, setCustomerArray, contractArray, setContractArray,
    genderArray, academicTitleArray, extensionArray, schedulingArray, subscriptionArray,
    serverArray, setServerArray, serverTypeArray,
    leadStageArray, leadSourceArray,
    disableChilds 
}) => {
    const isAdmin = getIsAdmin();
    const inputUpload = useRef<HTMLInputElement | null>(null);
    const inputFavUpload = useRef<HTMLInputElement | null>(null);
    
    const [currentPage, setCurrentPage] = useState(0);
    //
    const [userIdArray,setUserIdArray] = useState(editCompany.Users);
    const [contractIdArray,setContractIdArray] = useState(editCompany.Contracts);
    const [personIdArray,setPersonIdArray] = useState(editCompany.Customers);
    const [serverIdArray, setServerIdArray] = useState<number[]>([]);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setCurrentPage(newValue);
    };


    useEffect(() => {
        setEditCompany({
            ...editCompany,
            Users: userIdArray,
            Contracts: contractIdArray,
            Customers: personIdArray
        } as ICompany)
    },[userIdArray,contractIdArray,personIdArray])


    async function fileToBase64(file:File) : Promise<[string, string]> {
        let mime_type = file.type;

        let result_base64 = await new Promise((resolve) => {            
            let fileReader = new FileReader();
            fileReader.onload = (e) => resolve(fileReader.result);
            fileReader.readAsDataURL(file);
            console.log(file);
        });

        return [String(result_base64).split(",")[0].split(":")[1].split(";")[0], String(result_base64).split(",")[1]];
    }

    const handleFilesUpload = async (acceptedFiles:File[]) => {
        if (acceptedFiles.length === 1) {
            let currentFile = acceptedFiles[0];
            let updateCompany = {...editCompany};
            [updateCompany.logo_mime, updateCompany.logo] = await fileToBase64(currentFile);
            setEditCompany({...updateCompany})
        }
    }
    const handleNormalUpload = (files:null|FileList) => {
        if (files !== null) {
            let acceptedFiles:File[] = Array.from(files);
            handleFilesUpload(acceptedFiles);
        }
    }


    const handleFaviconUpload = async (acceptedFiles:File[]) => {
        if (acceptedFiles.length === 1) {
            let currentFile = acceptedFiles[0];
            let updateCompany = {...editCompany};
            let tmpFav = "";
            [tmpFav, updateCompany.favicon] = await fileToBase64(currentFile);
            setEditCompany({...updateCompany})
        }
    }
    const handleFaviconWrapperUpload = (files:null|FileList) => {
        if (files !== null) {
            let acceptedFiles:File[] = Array.from(files);
            handleFaviconUpload(acceptedFiles);
        }
    }



    return (
        <>
            <input 
                hidden 
                ref={inputUpload} 
                type="file" 
                //accept={mimeTypeArray.map(x => x.MIMEtype).join(", ") }
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleNormalUpload(event.target.files)} 
            />

            <input 
                hidden 
                ref={inputFavUpload} 
                type="file" 
                accept="image/x-icon"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFaviconWrapperUpload(event.target.files)} 
            />


            <Grid container spacing={2}>
                {(editCompany.id > 0 && (disableChilds === undefined || disableChilds === false)) && 
                    <Grid item xs={6}>
                        <Grid container spacing={2}>
                            <PersonTable
                                idCompany={editCompany.id}
                                personArray={customerArray}
                                setPersonArray={setCustomerArray}
                                personIdArray={personIdArray}
                                setPersonIdArray={setPersonIdArray}
                                academicTitleArray={academicTitleArray}
                                genderArray={genderArray}
                            />
                        </Grid>
                    </Grid>
                }



                
                <Grid item xs={editCompany.id < 0 || (disableChilds !== undefined && disableChilds === true) ? 12 : 6}>

                    <Grid container spacing={2} sx={{ mt: 2 }}>
                        <Grid item sm={6}>
                            <CustomeTextField
                                label="Kundennummer (LTB)"
                                attr="customer_number_lextorbyte"
                                type="string"
                                object={editCompany}
                                setObject={setEditCompany}
                                disabled={!isAdmin || editCompany.id > 0}
                            />
                        </Grid>
                        <Grid item sm={6}>
                            <CustomeTextField
                                label="Kundennummer (KM)"
                                attr="customer_number_kemto"
                                type="string"
                                object={editCompany}
                                setObject={setEditCompany}
                                disabled={!isAdmin}
                            />
                        </Grid>
                        <Grid item sm={3}>
                            <CustomeTextField
                                label="Quelle"
                                attr="CompanyLeadSource"
                                type="select"
                                object={editCompany}
                                setObject={setEditCompany}
                                disabled={!isAdmin}
                            >
                                {leadSourceArray.map(x => 
                                    <MenuItem key={`select-leadsource-${x.id}`} value={x.id}>
                                        {x.companyLeadSource}
                                    </MenuItem>
                                )}
                            </CustomeTextField>
                        </Grid>
                        <Grid item sm={3}>
                            <CustomeTextField
                                label="Status"
                                attr="CompanyLeadStage"
                                type="select"
                                object={editCompany}
                                setObject={setEditCompany}
                                disabled={!isAdmin}
                            >
                                {leadStageArray.map(x => 
                                    <MenuItem key={`select-leadsource-${x.id}`} value={x.id}>
                                        {x.companyLeadStage}
                                    </MenuItem>
                                )}
                            </CustomeTextField>
                        </Grid>
                        <Grid item sm={6}>
                            <CustomeTextField
                                label="Firma"
                                attr="company_name"
                                type="string"
                                object={editCompany}
                                setObject={setEditCompany}
                                disabled={!isAdmin}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ mt: 3 }}>
                        <Grid item sm={12}>
                            <CustomeTextField
                                label="Homepage"
                                attr="homepage"
                                type="string"
                                object={editCompany}
                                setObject={setEditCompany}
                                disabled={!isAdmin}
                            />
                        </Grid>

                        <Grid item sm={6}>
                            <CustomeTextField
                                label="Faxnummer"
                                attr="fax_number"
                                type="string"
                                object={editCompany}
                                setObject={setEditCompany}
                                disabled={!isAdmin}
                            />
                        </Grid>
                        <Grid item sm={6}>
                            <CustomeTextField
                                label="Telefon"
                                attr="phone_number"
                                type="string"
                                object={editCompany}
                                setObject={setEditCompany}
                                disabled={!isAdmin}
                            />
                        </Grid>

                        <Grid item sm={12}>
                            <CustomeTextField
                                label="E-Mail"
                                attr="email"
                                type="email"
                                object={editCompany}
                                setObject={setEditCompany}
                                disabled={!isAdmin}
                            />
                        </Grid>
                    </Grid>



                    <Accordion sx={{mt: 2}}>
                        <AccordionSummary>
                            Erweiterte Informationen
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2} sx={{ mt: 3 }}>
                                <Grid item sm={8}>
                                    <CustomeTextField
                                        label="Straße"
                                        attr="street"
                                        type="string"
                                        object={editCompany}
                                        setObject={setEditCompany}
    
                                        disabled={!isAdmin}
                                    />
                                </Grid>
                                <Grid item sm={4}>
                                    <CustomeTextField
                                        label="Nr."
                                        attr="street_nr"
                                        type="string"
                                        object={editCompany}
                                        setObject={setEditCompany}
    
                                        disabled={!isAdmin}
                                    />
                                </Grid>
                                <Grid item sm={12}>
                                    <PostcodeSearchV2
                                        idPostcode={editCompany.Postcode}
                                        setIdPostcode={(idPostcode: number) => setEditCompany({
                                            ...editCompany,
                                            Postcode: idPostcode
                                        })}
                                        required={false}
                                        allowNull
                                    />
                                </Grid>
                                <Grid item sm={6}>
                                    <CustomeTextField
                                        label="Ust-id"
                                        attr="vat_number"
                                        type="string"
                                        object={editCompany}
                                        setObject={setEditCompany}
                                        disabled={!isAdmin}

                                    />
                                </Grid>
                                <Grid item sm={6}>
                                    <CustomeTextField
                                        label="Str.Nr."
                                        attr="tax_number"
                                        type="string"
                                        object={editCompany}
                                        setObject={setEditCompany}
                                        disabled={!isAdmin}

                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary>
                            CI-Einstellungen
                        </AccordionSummary>
                        <AccordionDetails>

                            <Grid container spacing={2} sx={{ mt: 3 }}>
                            <Grid item sm={4}>
                                <CustomeTextField
                                    label="CI: Primäre Farbe "
                                    attr="ci_color_primary"
                                    type="color"
                                    object={editCompany}
                                    setObject={setEditCompany}

                                    disabled={!isAdmin}
                                />
                            </Grid>
                            <Grid item sm={4}>
                                <CustomeTextField
                                    label="CI: Sekundäre Farbe"
                                    attr="ci_color_secondary"
                                    type="color"
                                    object={editCompany}
                                    setObject={setEditCompany}

                                    disabled={!isAdmin}
                                />
                            </Grid>
                            <Grid item sm={4}>
                                <CustomeTextField
                                    label="CI: Text Farbe"
                                    attr="ci_color_text"
                                    type="color"
                                    object={editCompany}
                                    setObject={setEditCompany}

                                    disabled={!isAdmin}
                                />
                            </Grid>

                            <Grid item sm={6}>
                                <HTMLEditor
                                    key="htmledit-dsgvo"
                                    btnTitle="DSGVO"
                                    txt={editCompany.gdpr_html}
                                    setTxt={(txt:string) => setEditCompany({...editCompany, gdpr_html: txt})}
                                    disableEdit={!isAdmin}
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <HTMLEditor
                                    key="htmledit-imprint"
                                    btnTitle="Impressum"
                                    txt={editCompany.imprint_html}
                                    setTxt={(txt:string) => setEditCompany({...editCompany, imprint_html: txt})}
                                    disableEdit={!isAdmin}
                                />
                            </Grid>



                            <Grid item sm={12}>
                                <Typography>Favicon</Typography>
                                {(editCompany.favicon)
                                    &&                        
                                        <Box sx={{mt: 5}}   
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                                <img width="64" src={`data:image/x-icon;base64, ${editCompany.favicon}`} title="Favicon"/>
                                        </Box>
                                    
                                }
                                {isAdmin && <Button sx={{float: "left"}} onClick={() => (inputFavUpload !== null) && inputFavUpload.current?.click()} ><UploadFile/>Hochladen</Button>}
                            </Grid>

                            <Grid item sm={12}>
                                <Typography>Logo</Typography>
                                {(editCompany.logo !== "" && editCompany.logo_mime)
                                    &&                        
                                        <Box sx={{mt: 5}}   
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                                <img width={250} src={`data:${editCompany.logo_mime};base64, ${editCompany.logo}`} title="Logo"/>
                                        </Box>
                                    
                                }
                                {isAdmin && <Button sx={{float: "left"}} onClick={() => (inputUpload !== null) && inputUpload.current?.click()} ><UploadFile/>Hochladen</Button>}
                            </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>




                </Grid>
            </Grid>




            <Collapse in={editCompany.id > 0 && (disableChilds === undefined || disableChilds === false)}>
                <Box sx={{ width: '100%', mt: 5 }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={currentPage} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Benutzer" {...a11yProps(0)} />
                            <Tab label="Verträge" {...a11yProps(2)} />
                            <Tab label="Webseiten" disabled {...a11yProps(3)} />
                            <Tab label="DiNo" {...a11yProps(4)} />
                        </Tabs>
                    </Box>

                    <CustomTabPanel value={currentPage} index={0}>
                        <UserTable
                            userArray={userArray}
                            userIdArray={userIdArray}
                            setUserIdArray={setUserIdArray}
                            setUserArray={setUserArray}
                        />
                    </CustomTabPanel>

                    <CustomTabPanel value={currentPage} index={2}>
                        <ContractTable
                            idCompany={editCompany.id}
                            contractArray={contractArray}
                            setContractArray={setContractArray}
                            contractIdArray={contractIdArray}
                            setContractIdArray={setContractIdArray}
                            extensionArray={extensionArray}
                            schedulingArray={schedulingArray}
                            subscriptionArray={subscriptionArray}
                        />
                    </CustomTabPanel>

                    <CustomTabPanel value={currentPage} index={4}>
                        <ServerTable
                            idCompany={editCompany.id}
                            serverTypeArray={serverTypeArray}
                            serverArray={serverArray}
                            setServerArray={setServerArray}
                            serverIdArray={serverIdArray}
                            setServerIdArray={setServerIdArray}

                            contractArray={contractArray}
                            contractIdArray={contractIdArray}
                        />
                    </CustomTabPanel>


                </Box>
            </Collapse>

        </>
    )
}
